import {
    DATA_SUBMIT_ACTION,
    DATA_SUBMIT_SUCCESS,
    DATA_SUBMIT_ERROR,
} from '../actions';

const INIT_STATE = {
    DataSubmitDetails: [],
    error: ''
}

const addNewUserReducer = (state = INIT_STATE, action) => {   
    switch (action.type) {
        case DATA_SUBMIT_ACTION:
            return { ...state };
        case DATA_SUBMIT_SUCCESS:
            return { ...state, DataSubmitDetails: action.payload, error: '',  };
        case DATA_SUBMIT_ERROR:
            return { ...state, error: action.payload.message };
        default: return { ...state };
    }
}
export default addNewUserReducer