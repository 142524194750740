import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import
{
    DATA_SUBMIT_ACTION, getSearch
} from '../actions';
import
{
    dataSubmitSuccess,
    dataSubmitError,
} from './action';
import { api, DataEntryAPI } from '../../config/api'
import { getApiHeader } from '../../helpers/utils';
import { toast } from 'react-toastify';

function* dataEntryCall(payload)
{

    try {
        const data = yield call(dataEntryCallAsync, payload);
        yield put(getSearch());
        yield put(dataSubmitSuccess(data));
    } catch (error) {
        yield put(dataSubmitError(error));

    }
}
// axios({
//     method: 'post',
//     url: `${ DataEntryAPI }/UpdateRosterAttributeInfo`,
//     headers: getApiHeader(),
//     data: JSON.stringify(payload)
// }).then(function (res)
const dataEntryCallAsync = ({ payload }) =>
{
    axios({
        method: 'post',
        url: `${ DataEntryAPI }/InsertRosterUser`,
        headers: getApiHeader(),
        data: JSON.stringify(payload)
    }).then((res)=>
    {
        toast.success("User(s) added successfully.")
        return res;
    }).catch((err)=>
    {
        // toast.error(err)
        return toast.error("Something is wrong.")
    });
}

export function* watchDataEntry()
{
    yield takeEvery(DATA_SUBMIT_ACTION, dataEntryCall);
}

export default function* rootSaga()
{
    yield all([
        fork(watchDataEntry),
    ]);
}