
export const DataEntryAPI = process.env.REACT_APP_DATA_ENTRY_API
// export const DataEntryAPI = "https://localhost:44340/api/API";
export const AIMWithoutCred = process.env.REACT_APP_AIM_WITHOUT_CREDENTIAL

var host = window.location.host;
var hostArray = host.split('.');
var domain = (hostArray[0]);

var path = "";
if (domain === "localhost:3000" || domain === "amplifaiaim-dev") {
    path = "aimuat"
} else {
    path = domain;
}
export const domainName = path;