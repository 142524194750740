import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/global.css'
import './assets/css/Home.css'
import './assets/css/DataEntry.css'
import './assets/css/style.css'
import './assets/css/training.css'
import ErrorBoundary from './components/shared/ErrorBoundary';

const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App'));

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);