import {
    DATA_SUBMIT_ACTION,
    DATA_SUBMIT_SUCCESS,
    DATA_SUBMIT_ERROR,
} from '../actions';

export const dataSubmitAction = (data) => ({
    type: DATA_SUBMIT_ACTION,
    payload: data
});
export const dataSubmitSuccess = (info) => ({
    type: DATA_SUBMIT_SUCCESS,
    payload: info
});
export const dataSubmitError = (message) => ({
    type: DATA_SUBMIT_ERROR,
    payload: { message }
});


